@import '@usb-shield/themes/dist/library/styles/variables.scss';
@import '@usb-shield/design-tokens/dist/responsive-web/usb-light/usb-light.scss';
// @import '@usb-shield/themes/dist/styles/usb-light.css';


$token-spacing-1px: 0.0625rem;
$token-spacing-1-5px: 0.094rem;
$token-spacing-2px: 0.125rem;
$token-spacing-3px: 0.188rem;
$token-spacing-4px: 0.3rem;
$token-spacing-5px: 0.313rem;
$token-spacing-5-6px: 0.35rem;
$token-spacing-6px: 0.375rem;
$token-spacing-6-4px: 0.4rem;
$token-spacing-7px: 0.438rem;
$token-spacing-10px: 0.625rem;
$token-spacing-10-4px: 0.65rem;
$token-spacing-10-8px: 0.675rem;
$token-spacing-12px: 0.8rem;
$token-spacing-13-06px: 0.816rem;
$token-spacing-13-6px: 0.85rem;
$token-spacing-14px: 0.875rem;
$token-spacing-15px: 0.938rem;
$token-spacing-15-75px: 0.984rem;
$token-spacing-16px: 1rem;
$token-spacing-17-5px: 1.094rem;
$token-spacing-18px: 1.1rem;
$token-spacing-20-16px: 1.26rem;
$token-spacing-21px: 1.313rem;
$token-spacing-22-4px: 1.4rem;
$token-spacing-23-2px: 1.45rem;
$token-spacing-24px: 1.5rem;
$token-spacing-25px: 1.563rem;
$token-spacing-26px: 1.625rem;
$token-spacing-27-2px: 1.7rem;
$token-spacing-29px: 1.813rem;
$token-spacing-30px: 1.875rem;
$token-spacing-32px: 2rem;
$token-spacing-34px: 2.1rem;
$token-spacing-38px: 2.375rem;
$token-spacing-40px: 2.5rem;
$token-spacing-44px: 2.75rem;
$token-spacing-45px: 2.813rem;
$token-spacing-46px: 2.875rem;
$token-spacing-48px: 3rem;
$token-spacing-49px: 3.06rem;
$token-spacing-50px: 3.125rem;
$token-spacing-52px: 3.25rem;
$token-spacing-55px: 3.438rem;
$token-spacing-60px: 3.75rem;
$token-spacing-61-6px: 3.85rem;
$token-spacing-72px: 4.5rem;
$token-spacing-85px: 5.313rem;
$token-spacing-97px: 6.063rem;
$token-spacing-100px: 6.25rem;
$token-spacing-108px: 6.75rem;
$token-spacing-110px: 6.875rem;
$token-spacing-112px: 7rem;
$token-spacing-115px: 7.188rem;
$token-spacing-116px: 7.25rem;
$token-spacing-120px: 7.5rem;
$token-spacing-128px: 8rem;
$token-spacing-134px: 8.375rem;
$token-spacing-137px: 8.563rem;
$token-spacing-144px: 9rem;
$token-spacing-150px: 9.375rem;
$token-spacing-154px: 9.625rem;
$token-spacing-160px: 10rem;
$token-spacing-162px: 10.125rem;
$token-spacing-170px: 10.625rem;
$token-spacing-176px: 11rem;
$token-spacing-180px: 11.25rem;
$token-spacing-190px: 11.875rem;
$token-spacing-193px: 12.063rem;
$token-spacing-194px: 12.125rem;
$token-spacing-200px: 12.5rem;
$token-spacing-207px: 12.938rem;
$token-spacing-210px: 13.125rem;
$token-spacing-228px: 14.25rem;
$token-spacing-230px: 14.375rem;
$token-spacing-237px: 14.813rem;
$token-spacing-237-5px: 14.844rem;
$token-spacing-238-4px: 14.9rem;
$token-spacing-240px: 15rem;
$token-spacing-250px: 15.625rem;
$token-spacing-256px: 16rem;
$token-spacing-257-5px: 16.094rem;
$token-spacing-261px: 16.313rem;
$token-spacing-270px: 16.875rem;
$token-spacing-272px: 17rem;
$token-spacing-277px: 17.313rem;
$token-spacing-280px: 17.5rem;
$token-spacing-281px: 17.563rem;
$token-spacing-285px: 17.813rem;
$token-spacing-300px: 18.75rem;
$token-spacing-303px: 18.938rem;
$token-spacing-304px: 19rem;
$token-spacing-306px: 19.125rem;
$token-spacing-324px: 20.25rem;
$token-spacing-328px: 20.5rem;
$token-spacing-336px: 21rem;
$token-spacing-340px: 21.25rem;
$token-spacing-350px: 21.875rem;
$token-spacing-352px: 22rem;
$token-spacing-368px: 23rem;
$token-spacing-375px: 23.44rem;
$token-spacing-384px: 24rem;
$token-spacing-387px: 24.188rem;
$token-spacing-388px: 24.35rem;
$token-spacing-390px: 24.375rem;
$token-spacing-400px: 25rem;
$token-spacing-403px: 25.188rem;
$token-spacing-405px: 25.313rem;
$token-spacing-406px: 25.375rem;
$token-spacing-410px: 25.625rem;
$token-spacing-420px: 26.25rem;
$token-spacing-448px: 28rem;
$token-spacing-468px: 29.25rem;
$token-spacing-480px: 30rem;
$token-spacing-500px: 31.25rem;
$token-spacing-503px: 31.438rem;
$token-spacing-512px: 32rem;
$token-spacing-560px: 35rem;
$token-spacing-600px: 37.5rem;
$token-spacing-660px: 41.25rem;
$token-spacing-667px: 41.688rem;
$token-spacing-720px: 45rem;
$token-spacing-745px: 46.563rem;
$token-spacing-900px: 56.25rem;
$token-spacing-1024px: 64rem;
$token-spacing-1120px: 70rem;
$token-spacing-1280px: 80rem;
$token-spacing-1650px: 103.125rem;
$token-spacing-1768px: 110.5rem;
$token-spacing-1878-6px: 117.413rem;
$token-spacing-1920px: 120rem;
$token-font-size-12px: 0.75rem;
$token-font-size-14px: 0.875rem;
$token-font-size-18px: 1.125rem;
$token-font-size-32px: 2rem;
$token-font-size-6xl: 3.5rem;
$token-font-weight-100: 100;
$token-font-weight-200: 200;
$token-line-height-12px: 0.75rem;
$token-line-height-19px: 1.188rem;
$token-line-height-21px: 1.313rem;
$token-line-height-22px: 1.375rem;
$token-line-height-25px: 1.563rem;
$token-line-height-30px: 1.875rem;
$token-line-height-32px: 2rem;
$token-line-height-35px: 2.188rem;
$token-line-height-40px: 2.5rem;
$token-line-height-13: 3.25rem;
$token-line-height-60px: 3.75rem;
$token-line-height-96px: 6rem;
$token-line-height-base: 1;
$token-background-dark-mode: rgba(0, 0, 0, 0.3);
$token-color-base-blue: rgb(0, 117, 201);
$token-color-base-red: rgb(255, 0, 0);
$token-color-base-lighter-gray: rgb(51, 51, 51);
$token-color-base-light-gray: rgb(68, 68, 68);
$token-color-base-gray: rgb(74, 74, 74);
$token-color-base-dark-gray: rgb(29, 30, 31);
$token-color-base-darker-gray: rgb(50, 50, 50);
$token-color-base-darker-blue: rgb(12, 0, 51);
$token-color-base-dark-blue-gray: rgb(46, 46, 50);
$token-outline-neutral: rgb(220, 229, 249);
$token-outline-error: rgb(239, 201, 206);
$token-background-color-error: rgb(250, 237, 239);
$token-outline-warning: rgb(253, 229, 199);
$token-border-color: rgb(222, 222, 225);
$token-border-color-grey: rgb(204, 204, 204);
$token-border-color-neutral-30: rgb(204, 204, 210);
$token-border-right-color: rgb(151, 151, 151);
$token-border-bottom-color: rgb(216, 216, 216);
$code-anchor-text-blue: rgb(12,32,116);
$token-border-none: none;
$token-border-width-0: 0;
$token-border-width-2-05px: 2.05px;
$token-border-width-3px: 3px;
$token-border-radius-0: 0;
$token-border-radius-4px: 4px;
$token-border-radius-7px: 0.438rem;
$token-border-radius-10px: 0.625rem;
$token-shadow-show: "0 5px 11px rgba(0, 0, 0, 0.4)";
$token-shadow-modal: "0 10px 20px 0 rgb(0 0 0 / 15%)";
$token-shadow-card: "0px 4px 4px rgb(0 0 0 / 25%)";
$token-shadow-dropdown-menu: 0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
$token-shadow-dropdown-submenu: 0 6px 0 -4px rgba(0,0,0,.12), 0 0 0 -2px rgba(0,0,0,.6);
$token-shadow-drop: 0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 6px -1px rgba(0, 0, 0, 0.12);
$token-notice-link-color: rgb(48, 100, 217);

$edp-button-loud-background-color: rgb(222, 22, 43);

$toast-error-color: rgb(207, 42, 54);
$toast-success-color: rgb(0, 125, 30);

$heading2-text-color: $token-text-color-brand-primary;

$dark-tooltip-background-color: $token-text-color-brand-primary;
$dark-tooltip-content-text-color: $token-utility-white;

$stripe-background-color: $token-color-grey-10;
$home-bgcolor-white: rgb(255, 255, 255);
$hsOverviewSection-bgcolor: rgb(255, 255, 255);

$token-border-dark-color : rgb(18, 218, 159);
$token-background-light-color : rgb(232, 252, 246);
$hsOverviewSection-bgcolor: rgb(255, 255, 255);

$nonSupportedAreaWrapper-bgcolor: rgba(30, 30, 34);

$home-browseDocsSection-bgcolor: rgba(30, 30, 34);

$pageLayout-mainHeader-position: sticky;
$pageLayout-mainHeader-width: 100%;
$pageLayout-mainHeader-z-index: 999;
$pageLayout-footer-border-top: none;
$pageLayout-footer-border-top-mobile: none;
$pageLayout-footer-background: $token-background-primary;
$pageLayout-mainContentStyling-min-height: 85vh;
$pageLayout-acceptCookiesStyle-background: $token-background-brand-primary;
$pageLayout-footer-box-shadow: 0px -1px 3px 0px #0000001F;


$pageHeader-height: 3.875rem;
$twoTierPageHeader-height: $token-spacing-112px;
$twoTierPageHeader-topHeight: $token-spacing-112px;
$pageHeader-desktop-backgroundcolor: $token-background-brand-primary;
$pageHeader-box-shadow: 0px 3px 4px 0px #0000001F;
$pageHeader-gridWrapper-width: $token-spacing-1650px;
$pageHeader-fullWidth-width: $token-spacing-1120px;
$pageHeader-padding-left: $token-spacing-0;

$pageFooter-display: grid;
$pageFooter-block-margin: $token-spacing-0;
$pageFooter-width: auto;
$pageFooter-text-align: start;
$pageFooter-block-spacing: $token-spacing-0;
$pageFooter-pipeline-border-right: none;
$pageFooter-pipeline-right-spacing: $token-spacing-0;
$pageFooter-gridWrapper-width: $token-spacing-1650px;
$pageFooter-copyrightBlock-font-size: $token-font-size-root;
$pageFooter-copyrightBlock-justify-self-tablet: end !important;
$pageFooter-quicklinksListItem-display-mobile: block;
$pageFooter-quicklinksListItem-p-padding-left: $token-spacing-4; 
$pageFooter-quicklinksListItem-a-color: #2E2E32;
$pageFooter-quicklinksListItem-padding-mobile: $token-spacing-3 $token-spacing-0;
$pageFooter-quickLinkWithPipeMobile-border-right: $token-border-width-default solid $token-background-primary;
$pageFooter-quickLinkColor-color: $token-text-color-inverse-primary;
$pageFooter-disclaimerText-p-color: $token-text-color-inverse-primary;
$pageFooter-footerSection-padding: $token-spacing-8 $token-spacing-0;

$desktopNavBar-link-color: $link-inline-light-text-color;
$desktopNavBar-profileDetails-margin: $token-spacing-0;
$desktopNavBar-profileDetails-twoTier-padding: $token-spacing-24px $token-spacing-0 $token-spacing-0 $token-spacing-0;
$desktopNavBar-profileDetails-padding: $token-spacing-18px $token-spacing-0 $token-spacing-0 $token-spacing-0;
$desktopNavBar-profile-color: $token-text-color-inverse-primary;
$desktopNavBar-profile-icon-after-border: solid $token-background-primary;
$desktopNavBar-parentMenu-spacing: $token-spacing-0 $token-spacing-6-4px $token-spacing-0 $token-spacing-0;
$desktopNavBar-parentMenu-spacing-mobile: $token-spacing-0 $token-spacing-4px;
$desktopNavBar-parentMenu-loginButton-font-size: $token-font-size-base;
$desktopNavBar-parentMenu-a-margin-right: $token-spacing-0;
$desktopNavBar-parentMenuLink-padding: $token-spacing-3;
$desktopNavBar-parentMenuLink-padding-mobile: $token-spacing-5-6px;
$desktopNavBar-parentMenuLink-icon-after-border: solid $token-background-primary;
$desktopNavBar-parentMenuLink-border-width: $token-border-width-0 $token-border-width-large $token-border-width-large $token-border-width-0;
$desktopNavBar-logo-padding-top: $token-spacing-3;
$desktopNavBar-logo-width: $token-spacing-256px;
$desktopNavBar-navMenuItems-justify-content: left;
$desktopNavBar-navMenuItems-justify-content-mobile: center;
$desktopNavBar-navMenuItems-margin-left: $token-spacing-10;
$desktopNavBar-mainMenu-spacing: $token-spacing-0;
$desktopNavBar-mainMenu-spacing-mobile: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-8;
$desktopNavBar-fullWidth-width: $token-spacing-1120px;
$desktopNavBar-loginModal-border: $token-border-none;
$desktopNavBar-profileDropdown-border: $token-border-none;
$desktopNavBar-profileDropdown-top: $token-spacing-61-6px;
$desktopNavBar-profileDropdown-right: $token-spacing-5;
$desktopNavBar-profileDetailsFullWidth-profileDropdown-right: $token-spacing-15;
$desktopNavBar-logOffLink-background-color: $token-background-primary;
$desktopNavBar-logOffLink-padding: $token-spacing-8 $token-spacing-0 $token-spacing-0;
$desktopNavBar-logOffLink-margin: $token-spacing-4 $token-spacing-0;
$desktopNavBar-dropDownSubMenu-border-bottom: 1px solid rgba(0, 0, 0, 0.12);
$desktopNavBar-dropDownSubMenu-font-weight: $token-font-weight-reg;
$desktopNavBar-dropDownSubMenu-font-size: $token-font-size-sm;
$desktopNavBar-dropDownSubMenu-padding: $token-spacing-0;
$desktopNavBar-dropDownSubMenu-background-hover: $token-background-transparent;
$desktopNavBar-subMenuList-border-radius: unset;
$desktopNavBar-subMenuList-box-shadow: none;
$desktopNavBar-subMenuList-width: none;
$desktopNavBar-show-left: $token-spacing-0;
$desktopNavBar-whiteBGColor-padding: $token-spacing-3;
$desktopNavBar-subMenuLink-padding: $token-spacing-15px $token-spacing-0 $token-spacing-3 $token-spacing-3;
$desktopNavBar-show-top: $token-spacing-46px;
$twoTeirDesktopNavBar-show-top: $token-spacing-34px;
$twoTeirDesktopNavBar-menuSpacing: $token-spacing-10;
$desktopNavBar-menuWrapper-align-items: unset;
$desktopNavBar-selected-background-color: $token-background-primary;
$desktopNavBar-grandSubMenuLink-color: $token-neutral-90;
$desktopNavBar-dropDownSubMenuForElavon-color-hover: $token-background-brand-interaction;
$desktopNavBar-profile-width: $token-spacing-406px;
$desktopNavBar-profile-padding: $token-spacing-45px $token-spacing-8;

$displayViews-h1-color: $token-background-inverse-primary;
$displayViews-markdownContent-padding-top: $token-spacing-0;
$displayViews-MDTOCSectionWrapper-padding-top: $token-spacing-4;
$displayViews-leftSideColumn-top: $token-spacing-0;
$displayViews-leftSideColumn-padding-top: $token-spacing-4;
$displayViews-leftSideColumn-padding-right: $token-spacing-4;
$displayViews-markdown-anchor-padding-top: 0;
$displayViews-markdown-anchor-margin-top: 0;
$displayViews-leftSideGrid-display: grid;
$displayViews-leftSideColumn-flex: unset;
$displayViews-markdownContent-flex: unset;
$displayViews-markdownContent-width: 100%;
$displayViews-MDTOCSectionWrapper-MDTOCSectionContent-margin-top: unset;
$displayViews-TOCMenuList-a-word-break: normal;
$displayViews-MDTOCSectionWrapper-flex: unset;
$displayViews-markdownContent-p-word-break: break-all;
$displayViews-markdownContent-td-word-break: break-all;
$displayViews-markdownContent-table-td-word-break: break-word;
$displayViews-redocDiv-padding-left: calc(5.55556vw - 1.66667rem);

$mobileNavBar-icon-color: $token-background-primary;
$mobileNavBar-logo-width: $token-spacing-256px;
$mobileNavBar-loginLink-color: $token-text-color-inverse-primary;
$mobileNavBar-loginLink-active-color: $token-text-color-inverse-primary;
$mobileNavBar-loginLink-background: $token-background-transparent;
$mobileNavBar-loginLink-active-background: $token-background-transparent;
$mobileNavBar-loginLink-hover-background: $token-background-transparent;
$mobileNavBar-loginLink-border-color: unset;
$mobileNavBar-loginLink-border-width: unset;
$mobileNavBar-loginLink-border-radius: unset;
$mobileNavBar-loginLink-padding: unset;
$mobileNavBar-hamburgerIcon-padding: 0 4%;
$mobileNavBar-pageHeader-input-margin-left: $token-spacing-4;
$mobileNavBar-pageHeader-label-span-margin-left: $token-spacing-7;
$mobileNavBar-pageHeader-logoutBtn-padding: $token-spacing-4 $token-spacing-8 $token-spacing-8;
$mobileNavBar-pageHeaderMobile-logoutBtn-padding: $token-spacing-4 $token-spacing-6 $token-spacing-8;
$mobileNavBar-regionPickerMobileWrapper-margin: $token-spacing-4 $token-spacing-3 $token-spacing-4 $token-spacing-4;
$mobileNavBar-level-1-li-last-child-border-top: none;
$mobileNavBar-level-1-li-last-child-border-bottom: none;
$mobileNavBar-level1-nested-padding: $token-spacing-5 $token-spacing-3 $token-spacing-5 $token-spacing-5;

$fullPageBanner-banner-box-shadow: unquote($token-shadow-md);
$fullPageBanner-banner-padding: $token-spacing-6;
$fullPageBanner-banner-border: none;
$fullPageBanner-banner-border-radius: unset;
$fullPageBanner-banner-width: $token-spacing-400px;
$fullPageBanner-content-padding: unset;
$fullPageBanner-btn1-btn2-font-size: $token-font-size-base;
$fullPageBanner-btn1-btn2-line-heigh: $token-line-height-base;
$fullPageBanner-btn1-background-color: $button-loud-background-color;
$fullPageBanner-container-margin: $token-spacing-0;
$fullPageBanner-container-media-background: rgb(255, 255, 255);
$fullPageBanner-container-media-height: unset;
$fullPageBanner-container-margin-max-sm: $token-spacing-0;
$fullPageBanner-container-max-lg-padding: $token-spacing-0;
$fullPageBanner-container-max-lg-margin: $token-spacing-0;
$fullPageBanner-container-background: transparent;
$fullPageBanner-backImg-min-width: 100%;
$fullPageBanner-banner-margin-top: $token-spacing-12;
$fullPageBanner-banner-background: $token-background-primary;
$fullPageBanner-backImg-max-width: $token-spacing-1920px;
$fullPageBanner-backImg-max-height: $token-spacing-660px;
$fullPageBanner-backImg-width: calc(100%);
$fullPageBanner-backImg-height: calc(100%);
$fullPageBanner-backImg-object-fit: fill;
$fullPageBanner-backImg-sm-screen-object-position: right $token-spacing-240px top $token-spacing-0;
$fullPageBanner-backImg-screen-600-object-position: right $token-spacing-400px top $token-spacing-0;
$fullPageBanner-backImg-screen-1024-object-position: right $token-spacing-384px top $token-spacing-0;
$fullPageBanner-backImg-screen-600-width: 165%;
$fullPageBanner-bannerGrid-position: relative;
$fullPageBanner-bannerGrid-top: unset;
$fullPageBanner-bannerGrid-background: rgb(255, 255, 255);
$fullPageBanner-bannerGrid-screen-600-padding: $token-spacing-0;
$fullPageBanner-banner-max-sm-margin-top: (-$token-spacing-12);
$fullPageBanner-banner-max-lg-margin-top: (-$token-spacing-16);
$fullPageBanner-contentBlock-max-lg-width: 100%;
$fullPageBanner-contentBlock-max-sm-width: 100%;
$fullPageBanner-h1-font-weight: $token-font-weight-med;
$fullPageBanner-h2-font-size: $token-font-size-2xl;
$fullPageBanner-h2-max-sm-lg-font-size: $token-font-size-2xl;
$fullPageBanner-h2-font-weight: $token-font-weight-med;
$fullPageBanner-h2-line-height: $token-line-height-35px;
$fullPageBanner-h2-max-sm-lg-line-height: $token-line-height-35px;
$fullPageBanner-p-margin: $token-spacing-0;
$fullPageBanner-p-font-size: $token-font-size-base;
$fullPageBanner-p-line-height: $token-line-height-loose;

$heroBanner-twoColBannerSubHeader-font-size: $token-font-size-4xl;
$twoColBannerImageRightContainerBackgroundHeight: 20rem;

$allProductsPage-grid-column-start: 3;

$productsArrayWrapper-img-width: 100%;
$productsArrayWrapper-border-radius: $token-spacing-7px;
$productsArrayWrapper-img-border-radius: $token-border-radius-7px $token-border-radius-7px $token-border-width-0 $token-border-width-0;

$sideNavigationPanelSelect-text-font-weight: $token-font-weight-med;
$sideNavigationPanelSelect-selected-background: $token-background-transparent;

$productDocumentPage-h2-font-size: $token-font-size-2xl;
$productDocumentPage-h2-font-weight: $token-font-weight-med;
$productDocumentPage-a-font-color: $token-color-grey-90;
$productDocumentPage-a-font-weight: $token-font-weight-med;
$productDocumentPage-h3-font-size: $token-font-size-lg;

$productOverviewPage-h2-title-color: $token-background-inverse-primary;
$productOverviewPage-font-family: $token-font-base;
$productOverviewPage-h2-font-size: $token-font-size-lg;
$productOverviewPage-h2-line-height: $token-spacing-9;

$productOverviewPage-rightSideBar-h2-font-family: $token-font-base;
$productOverviewPage-rightSideBar-h2-font-weight: $token-font-weight-med;
$productOverviewPage-rightSideBar-h2-line-height: $token-line-height-tight;
$productOverviewPage-rightSideBar-h2-letter-spacing: $token-spacing-xs;

$productOverviewPage-sidebarLink-color: $token-color-brand-interaction;
$productOverviewPage-sidebarLink-font-weight: $token-font-weight-med;

$codeBlock-pre-background-color: $token-background-secondary;
$codeBlock-code-background-color: $token-background-secondary;
$codeBlock-code-color: $token-text-color-primary;

$usbTabs-background-color: $token-background-secondary;

$hljs-background: $token-background-secondary;
$hljs-color: rgb(84, 84, 84);
$hljs-comment-color: rgb(68, 68, 68);
$hljs-subst-color: rgb(68, 68, 68);
$hljs-doctag-name-color: rgb(68, 68, 68);
$hljs-keyword-attribute-color: rgb(68, 68, 68);
$hljs-selector-tag-color: rgb(68, 68, 68);
$hljs-attr-color: rgb(68, 68, 68);
$hljs-built-bullet-code-addition-color: rgb(68, 68, 68);
$hljs-literal-color: rgb(0, 90, 5);
$hljs-regexp-symbol-template-variable-link-selector-attr-pseudo-color: rgb(68, 68, 68);
$hljs-type-string-selector-id-class-quote-template-tag-deletion-color: rgb(136, 0, 0);
$hljs-title-section-color: rgb(68, 68, 68);
$hljs-meta-color: rgb(68, 68, 68);
$hljs-meta-keyword-color: rgb(9, 93, 43);
$hljs-params-color: rgb(68, 68, 68);
$hljs-function-color: rgb(68, 68, 68);
$hljs-number-color: rgb(136, 0, 0);
$usbBadge-text-gray: rgb(46, 46, 50);
$usbBadge-icon-green: rgb(0, 125, 30);
$usbBadge-icon-red: rgb(207, 42, 54);
$usbBadge-background-green: rgb(242, 255, 244);
$usbBadge-background-red: rgb(255, 233, 234);
$usbBadge-background-tan: rgb(255, 244, 228);
$usbBadge-success-border: rgb(255, 244, 228);
$usbBadge-positive-border: rgb(160,220,170);
$usbBadge-negative-border: rgb(255,190,180);
$usbBadge-information-border: rgb(255,213,137);

$usbBadge-border-blue: rgb(170, 210, 255);
$usbBadge-border-skyblue: rgb(238, 246, 255);
$breadCrumb-breadcrumbText-margin: $token-spacing-4 $token-spacing-0;

$markdownWithToc-markdownTitle-color: inherit;

$regionPicker-regionPickerMobileWrapper-margin: $token-spacing-6 $token-spacing-5 $token-spacing-6 $token-spacing-5;
$regionPicker-regionPickerTabletWrapper-margin: $token-spacing-6 $token-spacing-11 $token-spacing-6 $token-spacing-8;

$searchPage-filterResultContainer-max-width: 74%;
$searchPage-contentWrapper-searchContainerCustom-input-width: 100%;
$searchPage-contentWrapper-mainTitle-color: rgb(13, 32, 116);
$searchPage-contentWrapper-searchResultsWrapper-productTitle-a-text-decoration: underline;
$searchPage-contentWrapper-searchResultsWrapper-productTitle-a-hover-text-decoration: none;
$searchPage-contentWrapper-searchResultsWrapper-productTitle-productTag-background-color: rgb(245, 245, 244);
$searchPage-contentWrapper-searchResultsWrapper-productTitle-productTag-border: $token-spacing-xs solid rgb(224, 224, 224);
$searchPage-contentWrapper-pagination-text-align: end;

$inputSearch-searchContainer-usbSearch-input-font-size: $token-spacing-14px;
$inputSearch-searchContainer-usbSearch-input-width: 175px;
$inputSearch-searchContainer-usbSearch-input-width-mobile: 175px;
$inputSearch-searchContainer-margin-top: $token-spacing-4;
$inputSearch-searchContainer-usbSearch-input-padding: $token-spacing-1-5px $token-spacing-12px $token-spacing-1-5px $token-spacing-10;
$inputSearch-searchContainer-usbSearch-label-icon-margin-top: $token-spacing-2px;
$inputSearch-searchContainer-usbSearch-label-icon-svg-height:$token-spacing-5;
$inputSearch-searchContainer-usbSearch-label-icon-svg-width: $token-spacing-5;
$inputSearch-searchContainer-margin-right: $token-spacing-base;
$inputSearch-desktopSearchContainer-usbSearch-label-icon-svg-height:$token-spacing-4;

$markdownWithToc-markdownTitle-color: inherit;

$acceptTermsOfUse-acceptTOUTitle-color: $token-text-color-brand-primary;
$acceptTermsOfUse-acceptTOUBtn-secondary-color: $button-secondary-text-color;

$stackItem-table-rightColumnAction: inline-flex;


$twoColWithImage-lightBlueCtaLink-color: rgb(170, 210, 255);
$setPassword-errorLink-color: #0a41c5;

// Delected tokens from design-tokens 4.23 (not present in 10.x versions--no clear substitute
$token-background-gradient-primary-hover: linear-gradient(180deg, rgb(10, 65, 197) 0%, rgb(12, 32, 116) 100%);

$all-docs-overview-description-color: rgba(44, 44, 44, 1);
$generic-grey-color-1: rgba(76, 76, 76, 1);

$showHide-showButton-margin: $token-spacing-0 !important;
$showHide-showButton-border-bottom: inherit;
$showHide-showButton-border-bottom-left-radius: inherit;
$showHide-showButton-border-bottom-right-radius: inherit;

$credentialsInput-wrapper-border: $token-border-width-default solid $token-border-base;
$credentialsInput-input-border-bottom: none;
$credentialsInput-input-border-bottom-left-radius: inherit;
$credentialsInput-input-border-bottom-right-radius: inherit;

$addUserPage-fieldWrapperDisplay: block;

// Custom Mixins
@mixin headingFont {
  font-size: $token-font-size-3xl;
  font-weight: $token-font-weight-med;
  -webkit-font-smoothing: antialiased;
}

@mixin headH1 {
  @include headingFont;
  font-weight: $token-font-weight-bold;
  line-height: $token-spacing-10;
  color: $displayViews-h1-color;
  -webkit-font-smoothing: antialiased;
}

@mixin headH2 {
  @include headingFont;
  line-height: $token-line-height-40px;
  color: $heading2-text-color;
  margin-bottom: $token-spacing-base;
  -webkit-font-smoothing: antialiased;
}

@mixin headH3 {
  font-size: $token-font-size-base;
  font-weight: $token-font-weight-reg;
  line-height: $token-line-height-loose;
  color: $token-color-grey-90;
  -webkit-font-smoothing: antialiased;
}

@mixin paragraph {
  margin-bottom: $token-spacing-2;
  @include headH3;
}

@mixin paragraphP {
  padding: $token-spacing-0 $token-spacing-0 $token-spacing-2 $token-spacing-0;
}

@mixin listUl {
  display: block;
  list-style-type: disc;
  margin-block-start: $token-spacing-4;
  margin-block-end: $token-spacing-4;
  margin-inline-start: $token-spacing-0;
  margin-inline-end: $token-spacing-0;
}

@mixin listOl {
  display: block;
  margin-block-start: $token-spacing-4;
  margin-block-end: $token-spacing-4;
  margin-inline-start: $token-spacing-0;
  margin-inline-end: $token-spacing-0;
}

@mixin linkFocused {
  &:focus {
    outline: $token-color-brand-interaction auto $token-spacing-xs !important;
    outline-offset: $token-spacing-1;
  }
}

@mixin menuLink {
  color: $productDocumentPage-a-font-color;
  font-weight: $sideNavigationPanelSelect-text-font-weight;
  font-size: $token-font-size-base;
  text-decoration: none !important;
  padding: $token-spacing-6px $token-spacing-2;

  &:hover {
    color: $token-text-color-interaction !important;
  }

  &:focus:before {
    content: none;
  }
}

@mixin menuLinkFocused {
  &:hover {
    background-color: $token-background-secondary;
    color: $token-text-color-interaction;
    cursor: pointer;

    a {
      color: $token-text-color-interaction;
    }

    svg {
      fill: $token-text-color-interaction;
    }
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: $token-border-width-large solid $token-text-color-interaction;
    outline-offset: (-$token-spacing-small);
  }
}

@mixin menuItemStyle {
   a, span span  {
    @include menuLink;
    @content;
  }
}

@mixin menuItemLevelStyle($n) {
  .outerLi-#{$n} {
    @include menuItemStyle {
        @content;
    }
  }
}

// Custom Breakpoints
$usb-screen-425: 425px;
$usb-screen-600: 600px;
$usb-screen-750: 750px;
$usb-screen-759: 759px;
$usb-screen-767: 767px;
$usb-screen-820: 820px;
$usb-screen-1024: 1024px;
$usb-screen-1055: 1055px;
$usb-screen-1368: 1368px;
// Custom Breakpoints as queries
$usb-min-1024: "screen and (min-width:" + $usb-screen-1024 + ")";

$usb-max-sm: "screen and (max-width:" + $token-breakpoint-small + ")";
$usb-max-md: "screen and (max-width:" + $token-breakpoint-medium + ")";
$usb-max-lg: "screen and (max-width:" + $token-breakpoint-large + ")";
$usb-max-xl: "screen and (max-width:" + $token-breakpoint-x-large + ")";
$usb-max-xl-767: "screen and (max-width:" + $usb-screen-767 + ")";
$usb-max-425: "screen and (max-width:" + $usb-screen-425 + ")";
$usb-max-600: "screen and (max-width:" + $usb-screen-600 + ")";
$usb-max-750: "screen and (max-width:" + $usb-screen-750 + ")";
$usb-max-759: "screen and (max-width:" + $usb-screen-759 + ")";
$usb-max-820: "screen and (max-width:" + $usb-screen-820 + ")";
$usb-max-1024: "screen and (max-width:" + $usb-screen-1024 + ")";
$usb-max-1055: "screen and (max-width:" + $usb-screen-1055 + ")";
$usb-max-1368: "screen and (max-width:" + $usb-screen-1368 + ")";
$usb-min-1368: "screen and (min-width:" + $usb-screen-1368 + ")";

$right-link-spacing: $token-spacing-0;
$right-link-fontsize:$token-font-size-root;

$headH2-font-color: $token-text-color-brand-primary;

// COOKIES
$cookiesBanner-marginLayout-flex-direction: row-reverse;
@import './custom-variables-styles/cookie-styling.scss';